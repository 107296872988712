export default {
    ajax: 0,
    branchList: [],
    branchType: [],
    branchCuster: [],
    devicesList: [],
    devicesModel: [],
    devicesCate: [],
    tableList: [],
    tableSize: [],
    tableType: [],
      
};