import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

const loggerMiddlewar = createLogger();

export default ({ initialState, middlewares = [], enhancers = [] }) => {
  const middlewareEnhancer = applyMiddleware(
    loggerMiddlewar,
    thunkMiddleware,
    ...middlewares
  );
  const composedEnhancers = compose(middlewareEnhancer, ...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  return store;
};
