export const ON_AJAX_BEGIN = "ON_AJAX_BEGIN";
export const ON_AJAX_SUCCESS = "ON_AJAX_SUCCESS";
export const ON_AJAX_ERROR = "ON_AJAX_ERROR";

export const LOAD_BRANCHLIST_BEGIN = "LOAD_BRANCHLIST_BEGIN";
export const LOAD_BRANCHLIST_SUCCESS = "LOAD_BRANCHLIST_SUCCESS";
export const LOAD_BRANCHLIST_ERROR = "LOAD_BRANCHLIST_ERROR";

export const LOAD_BRANCHTYPE_BEGIN = "LOAD_BRANCHTYPE_BEGIN";
export const LOAD_BRANCHTYPE_SUCCESS = "LOAD_BRANCHTYPE_SUCCESS";
export const LOAD_BRANCHTYPE_ERROR = "LOAD_BRANCHTYPE_ERROR";

export const LOAD_BRANCHCUSTER_BEGIN = "LOAD_BRANCHCUSTER_BEGIN";
export const LOAD_BRANCHCUSTER_SUCCESS = "LOAD_BRANCHCUSTER_SUCCESS";
export const LOAD_BRANCHCUSTER_ERROR = "LOAD_BRANCHCUSTER_ERROR";

export const LOAD_DEVICESLIST_BEGIN = "LOAD_DEVICESLIST_BEGIN";
export const LOAD_DEVICESLIST_SUCCESS = "LOAD_DEVICESLIST_SUCCESS";
export const LOAD_DEVICESLIST_ERROR = "LOAD_DEVICESLIST_ERROR";

export const LOAD_DEVICESMODEL_BEGIN = "LOAD_DEVICESMODEL_BEGIN";
export const LOAD_DEVICESMODEL_SUCCESS = "LOAD_DEVICESMODEL_SUCCESS";
export const LOAD_DEVICESMODEL_ERROR = "LOAD_DEVICESMODEL_ERROR";

export const LOAD_DEVICESCATE_BEGIN = "LOAD_DEVICESCATE_BEGIN";
export const LOAD_DEVICESCATE_SUCCESS = "LOAD_DEVICESCATE_SUCCESS";
export const LOAD_DEVICESCATE_ERROR = "LOAD_DEVICESCATE_ERROR";

export const LOAD_TABLELIST_BEGIN = "LOAD_TABLELIST_BEGIN";
export const LOAD_TABLELIST_SUCCESS = "LOAD_TABLELIST_SUCCESS";
export const LOAD_TABLELIST_ERROR = "LOAD_TABLELIST_ERROR";

export const LOAD_TABLESIZE_BEGIN = "LOAD_TABLESIZE_BEGIN";
export const LOAD_TABLESIZE_SUCCESS = "LOAD_TABLESIZE_SUCCESS";
export const LOAD_TABLESIZE_ERROR = "LOAD_TABLESIZE_ERROR";

export const LOAD_TABLETYPE_BEGIN = "LOAD_TABLETYPE_BEGIN";
export const LOAD_TABLETYPE_SUCCESS = "LOAD_TABLETYPE_SUCCESS";
export const LOAD_TABLETYPE_ERROR = "LOAD_TABLETYPE_ERROR";
