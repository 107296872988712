import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.devicesCate, action) => {
	switch (action.type) {
		case types.LOAD_DEVICESCATE_SUCCESS:
			return action.data;

		default:
			return state;
	}
};