import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { history } from "./_helpers";
import { alertActions } from "./userAuth/_actions";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// user authentication
const PrivateRoute = React.lazy(() =>
  import("./userAuth/_components/PrivateRoute")
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Branch = React.lazy(() => import("./views/Pages/Branch/Branch"));
const BranchUpdate = React.lazy(() =>
  import("./views/Pages/Branch/BranchUpdate")
);
const BranchAdd = React.lazy(() => import("./views/Pages/Branch/BranchAdd"));

const Device = React.lazy(() => import("./views/Pages/Devices/Devices"));
const DeviceUpdate = React.lazy(() =>
  import("./views/Pages/Devices/DevicesUpdate")
);
const DeviceAdd = React.lazy(() => import("./views/Pages/Devices/DevicesAdd"));

const Table = React.lazy(() => import("./views/Pages/Table/Table"));
const TableUpdate = React.lazy(() => import("./views/Pages/Table/TableUpdate"));
const TableAdd = React.lazy(() => import("./views/Pages/Table/TableAdd"));

const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }
  render() {
    const { alert } = this.props;
    return (
      <>
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <PrivateRoute
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
              <Route
                path="/branch"
                name="Branch"
                render={(props) => <Branch {...props} />}
              />
              <Route
                path="/BranchUpdate/:id"
                name="Branch update"
                render={(props) => <BranchUpdate {...props} />}
              />
              <Route
                path="/BranchAdd/:id"
                name="Branch add"
                render={(props) => <BranchAdd {...props} />}
              />
              <Route
                path="/deives"
                name="Device"
                render={(props) => <Device {...props} />}
              />
              <Route
                path="/deivesUpdate/:id"
                name="Device update"
                render={(props) => <DeviceUpdate {...props} />}
              />
              <Route
                path="/deivesAdd/:id"
                name="Device add"
                render={(props) => <DeviceAdd {...props} />}
              />
              <Route
                path="/table"
                name="Table"
                render={(props) => <Table {...props} />}
              />
              <Route
                path="/tableUpdate/:id"
                name="Table update"
                render={(props) => <TableAdd {...props} />}
              />
              <Route
                path="/tableAdd/:id"
                name="Table add"
                render={(props) => <TableUpdate {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(App);
