import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.ajax, action) => {
	if (action.type === types.ON_AJAX_BEGIN) {
		return state + 1;
	} else if (
		action.type === types.ON_AJAX_ERROR ||
		action.type === types.ON_AJAX_SUCCESS
	) {
		return state - 1;
	}

	return state;
};
