import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.tableList, action) => {
	switch (action.type) {
		case types.LOAD_TABLELIST_SUCCESS:
			return action.data;

		default:
			return state;
	}
};