import { combineReducers } from "redux";

import ajax from "./ajaxReducer";
import branchList from "./branchListReducer";
import branchType from "./branchTypeReducer";
import branchCuster from "./branchCusterReducer";
import devicesList from "./devicesListReducer";
import devicesModel from "./devicesModelReducer";
import devicesCate from "./devicesCateReducer";
import tableList from "./tableListReducer";
import tableSize from "./tableSizeReducer";
import tableType from "./tableTypeReducer";
import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";

const rootReducer = combineReducers({
  ajax,
  authentication,
  branchList,
  branchType,
  branchCuster,
  devicesList,
  devicesModel,
  devicesCate,
  tableList,
  tableSize,
  tableType,
  registration,
  users,
  alert,
});

export default rootReducer;
